import { getFileExtensionFromType } from "utils";

const { ROOT_URL } = require("config");

const getPresignedUrl = async (file) => {
  try {
    const fileExtension = getFileExtensionFromType(file.type);
    const response = await fetch(ROOT_URL + `/citizen/presigned_post`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        extension: fileExtension
      }),
    });

    return await response.json();
  } catch (Exception) {
    console.error(Exception);
  }
};

const postFileToS3 = async (file, data) => {
  const formData = new FormData();
  const { url, fields: {
    key,
    AWSAccessKeyId,
    policy,
    signature
  } } = data;
  formData.set('key', key);
  formData.set('AWSAccessKeyId', AWSAccessKeyId);
  formData.set('policy', policy);
  formData.set('signature', signature);
  formData.set('file', file);
  try {
    const response = await fetch(url, {
      method: "POST",
      body: formData,
    });

    return await response.json();
  } catch (Exception) {
    console.error(Exception);
  }
};

export {
  getPresignedUrl,
  postFileToS3
}