export const yearOptions = () => {
  const yearStart = 1900;

  const yearsArr = [];

  let year = new Date().getFullYear();

  while (year >= yearStart) {
    yearsArr.push({ value: year, label: year });
    year--;
  }

  return yearsArr;
};

const monthsLong = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const monthsShort = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const monthsOptions = (longName = false) => {
  const monthsArr = longName ? monthsLong : monthsShort;
  return monthsArr.map((m, index) => ({ value: index + 1, label: m }));
};

export const daysOptions = (selectedMonth, selectedYear) => {
  const isLeapYear = (selectedYear - 1900) % 4 === 0;

  let daysCount = 30;
  if ([1, 3, 5, 7, 8, 10, 12].includes(selectedMonth)) {
    daysCount = 31;
  } else if (selectedMonth === 2) {
    if (isLeapYear) {
      daysCount = 29;
    } else {
      daysCount = 28;
    }
  }

  const datesArr = [];

  let count = 1;
  while (count <= daysCount) {
    datesArr.push({ value: count, label: count });
    count++;
  }

  return datesArr;
};
