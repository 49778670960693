import React, { useRef } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { Modal } from "antd";
import styled from "styled-components";

const StyledModal = styled(Modal)`
  .ant-modal-footer {
    .ant-btn {
      border-radius: 8px;
    }
    .ant-btn-primary {
      background-color: #2d378c;
      border-color: #2d378c;
      span {
        color: #ffffff;
      }
    }
  }
`;
const ImageCropperDialog = ({ open, onClose, imageFile, getImage, ...rest }) => {
  const cropperRef = useRef(null);

  const handleCrop = () => {
    if (cropperRef.current) {
      const cropper = cropperRef.current.cropper;
      if (cropper) {
        const croppedCanvas = cropper.getCroppedCanvas();
        if (getImage) {
          croppedCanvas.toBlob(blob => {
            const newImageFile = new File([blob], imageFile.name, { type: imageFile.type });
            getImage(newImageFile);
            handleClose();
          });
        }
      }
    }
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <StyledModal
      visible={open}
      open={open}
      onOk={handleCrop}
      onCancel={handleClose}
      title="Crop Image"
      maskClosable={false}>
      {imageFile ? (
        <Cropper
          ref={cropperRef}
          src={URL.createObjectURL(imageFile)}
          style={{ height: 400, width: "100%" }}
          aspectRatio={1}
          {...rest}
        />
      ) : null}
    </StyledModal>
  );
};

export default ImageCropperDialog;
