import { Avatar, Col, Divider, Row } from "antd";
import React from "react";
import { AntDesignOutlined } from "@ant-design/icons";
import { styled } from "styled-components";
import { Spin } from "antd";
import { format, parseISO } from "date-fns";

const StyledIdNumber = styled.div`
  width: 100%;
  padding: 18px;
  border-radius: 15px;
  border: 0px solid #7C7C7C;
  background: #F7F7F7;
  text-align: center;
  span {
    color: #4D4D4D;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
  }
`;

const StyledTitle = styled.div`
  margin-bottom: 0;
  font-weight: 700;
  color: #4D4D4D;
  font-size: 20px;
  line-height: normal;
  @media only screen and (min-width: 700px) {
    color: white !important;
  }
`;

const StyledDivider = styled(Divider)`
  margin: 7px 0px !important;

  @media only screen and (min-width: 700px) {
    border-top: 1px solid white !important;
  }
`;

const StyledSubTitle = styled.span`
  text-align: center;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.257px;
  color: #979797;
  @media only screen and (min-width: 700px) {
    color: white !important;
  }
`;

const AddressTypography = styled.span`
  color: #4D4D4D;
  padding-top: 10px;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.257px;
  @media only screen and (min-width: 700px) {
    color: white !important;
  }
`;

const topColor = "#1E50A3";
const bottomColor = "#DE242A";

const CustomAvatar = styled(Avatar)`
  &&& {
    border-radius: 15px;
    border: 3px solid transparent;
    background-image: linear-gradient(to bottom, ${topColor}, ${bottomColor});
  }
`;

const CitizenProfile = citizen => {
  const { profile, first_name, last_name, birth_date, phone_number, address, card_number } = citizen;

  if (!citizen || !citizen.id) {
    return <Spin />;
  }

  return (
    <>
      <StyledIdNumber>
        <span>ID No: {card_number}</span>
      </StyledIdNumber>
      <Row gutter={0}>
        <Col span={10}>
          <CustomAvatar shape="square" size={115} icon={<AntDesignOutlined />} src={profile} />
        </Col>
        <Col span={14}>
          <Row>
            <StyledTitle bold level={5}>
              {last_name} ,
            </StyledTitle>
          </Row>
          <Row>
            <StyledTitle bold level={5}>
              {first_name}
            </StyledTitle>
          </Row>
          <StyledDivider />
          <Row gutter={5}>
            <Col span={12}>
              <StyledSubTitle level={5}>
                {birth_date ? format(parseISO(birth_date), "MMMM dd, yyyy") : null}
              </StyledSubTitle>
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <StyledSubTitle level={5}>{phone_number}</StyledSubTitle>
            </Col>
          </Row>
          <Row>
            <AddressTypography>{address}</AddressTypography>
          </Row>
        </Col>
        <Divider style={{ marginBottom: 0 }} />
      </Row>
    </>
  );
};

export default CitizenProfile;
