import React, { useState } from "react";
import { Form } from "antd";
import { useNavigate } from "react-router-dom";
import { redeemSocialService } from "services/citizen";
import { useSocialServiceContext } from "../Context";
import StyledButton from "components/button/Button";
import styled from "styled-components";
import FormFieldItem from "./FormFieldItem";
import ProgressOverlay from "components/progress/ProgressOverlay";

const RedeemButton = styled(StyledButton)`
  background: #0038A8;
  
  @media only screen and (min-width: 900px) {
    background-color: #CE1126;
    border-color: #CE1126;
  }
`;
const BackButton = styled(StyledButton)`
  background: rgba(242, 242, 242, 0.80);
  span {
    color: #000;
  }
`;

const RedeemForm = () => {
  const navigate = useNavigate();
  const { citizen } = useSocialServiceContext();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const [form] = Form.useForm();

  const onFinish = async () => {
    try {
      setIsLoading(true);
      await redeemSocialService({
        citizen: citizen.id,
      });
      navigate(`/citizen/${citizen.id}`);

      setIsLoading(false);
      setError('');
    } catch (err) {
      console.error(err);
      setIsLoading(false);
      setError('There was an error. Please try again later.');
    }
  };

  const onFinishFailed = errorInfo => {
    console.log("Failed:", errorInfo);
  };

  const onBack = () => {
    navigate('/scan');
  }

  return (
    <Form
      form={form}
      name="basic"
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 24,
      }}
      style={{
        minWidth: "90%",
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off">
      {
        isLoading && <ProgressOverlay description="Processing Request..." />
      }
      {
        error && <ProgressOverlay
          title="Error"
          titleProps={{
            type: 'danger'
          }}
          description={error}
          onClose={() => setError('')}
          isSpin={false}
        />
      }
      <Form.Item>
        <FormFieldItem label="Agency" value="DSWD" />
      </Form.Item>
      <Form.Item>
        <FormFieldItem label="Social Service" value="Cash and Rice Assistance" />
      </Form.Item>
      <Form.Item>
        <FormFieldItem label="Program Type" value="Financial" />
      </Form.Item>
      <Form.Item>
        <FormFieldItem label="Estimated Value" value="PHP 1600.00" />
      </Form.Item>
      <Form.Item>
        <BackButton onClick={onBack}>
          Back
        </BackButton>
      </Form.Item>
      <Form.Item>
        <RedeemButton type="primary" htmlType="submit">
          Redeem
        </RedeemButton>
      </Form.Item>
    </Form>
  );
};

export default RedeemForm;
