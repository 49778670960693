import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import NewCitizenContextProvider from "../src/pages/Register/Form/NewCitizenContext";
import SocialServiceProvider from "pages/SocialService/Provider";
import RegisterProvider from "pages/Register/Provider";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <RegisterProvider>
        <NewCitizenContextProvider>
          <SocialServiceProvider>
            <App />
          </SocialServiceProvider>
        </NewCitizenContextProvider>
      </RegisterProvider>
    </BrowserRouter>
  </React.StrictMode>,
);
