import React, { useContext } from "react";
import { Routes, Route } from "react-router-dom";
import GlobalStyle from "./GlobalStyles";
import { Spin } from "antd";
import NewCitizen from "./pages/Register/Form";
import "antd/dist/antd.min.css";
import RegisterHomepage from "./pages/Register/Homepage/RegisterHomePage";
import TermsAndConditions from "pages/Register/Form/steps/TermsAndCondition";
import { NewCitizenContext } from "pages/Register/Form/NewCitizenContext";
import QrScanPage from "pages/QRScan/QrScanPage";
import Error404Page from "pages/Error/404";
import CitizenDetail from "pages/SocialService/CitizenDetail/CitizenDetail";
import Redeem from "pages/SocialService/Redeem/Redeem";
import SearchPage from "pages/Search/components/SearchPage";
import { useRegisterContext } from "pages/Register/Context";

function App() {
  const { agreement } = useContext(NewCitizenContext);
  const { isAuthenticated } = useRegisterContext();
  const isAuthenticatedAgreed = isAuthenticated && Boolean(agreement);

  return (
    <>
      <GlobalStyle />
      <React.Suspense fallback={<Spin />}>
        <Routes>
          <Route path="/" element={!isAuthenticated ? <RegisterHomepage /> : <TermsAndConditions />} />
          <Route exact path="/register" element={isAuthenticatedAgreed ? <NewCitizen /> : isAuthenticated ? <TermsAndConditions /> : <RegisterHomepage />} />
          <Route exact path="/agreement" element={isAuthenticated ? <TermsAndConditions /> : <RegisterHomepage />} />
          <Route path="*" element={<Error404Page />} />
          <Route exact path="/scan" element={<QrScanPage />} />
          <Route exact path="/search" element={<SearchPage />} />
          <Route exact path="/citizen/:id" element={<CitizenDetail />} />
          <Route exact path="/citizen/:id/redeem" element={<Redeem />} />
        </Routes>
      </React.Suspense>
    </>
  );
}

export default App;
