import { createContext, useContext } from "react";

const context = createContext({});

const useSocialServiceContext = () => {
  const socialServiceContext = useContext(context);
  if (socialServiceContext === undefined) {
    throw new Error(`useSocialServiceContext must be used within a SocialServiceProvider`);
  }
  return socialServiceContext;
};

export { useSocialServiceContext };
export default context;
