import React from 'react';
import styled from "styled-components";

const Container = styled.div`
  position: relative;
  border-radius: 10px;
  border: 1px solid rgba(207, 207, 213, 0.50);
  margin-top: 15px;
  .label-container {
    position: absolute;
    top: -31px;
    left: 0;
    padding: 4px 8px;
    background-color: #FFFFFF;
    .label {
      font-size: 12px;
      color: #5F5D70;
      font-weight: 600;
    }
  }
  .value-container {
    padding: 11px 26px;
    .value {
      color: #979797;
    }
  }
`
const FormFieldItem = ({ label, value }) => {

  return (
    <Container>
      <div className="label-container">
        <span className="label">{label}</span>
      </div>
      <div className="value-container">
        <span className="value">{value}</span>
      </div>
    </Container>
  );
}
export default FormFieldItem;
