import React from 'react';
import styled from 'styled-components';
import { useRegisterContext } from '../Context';
import { Badge, Button } from 'antd';
import { useNavigate } from 'react-router-dom';

const Container = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  z-index: 11;
  .btn-logout {
    margin-left: 8px;
    border-radius: 20px;
    font-size: 12px;
    line-height: 18px;
    height: 20px;
  }
`
const CodeLogout = () => {
  const navigate = useNavigate();
  const { userCode, logout } = useRegisterContext();

  const handleLogout = () => {
    logout();
    navigate('/');
  }

  return (
    <Container>
      <Badge count={userCode} />
      <Button size='small' className='btn-logout' onClick={handleLogout}>Change Code</Button>
    </Container>
  )
}
export default CodeLogout;