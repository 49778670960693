import { Button } from "antd";
import { styled } from "styled-components";

const StyledButton = styled(Button)`
  border-radius: 8px;
  width: 100%;
  height: fit-content;
  padding: 10px 16px;
  background: #0038A8;
  width: 100%;
  span {
    color: #ffff;
  }
`;

export default StyledButton;
