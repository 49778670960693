import React, { useState } from "react";
import { Alert, Button } from "antd";
import { useNavigate } from "react-router-dom";
import { StyledElement as BackgroundContainer } from "pages/SocialService/components/reusables";
import { useZxing } from "react-zxing";
import styled from "styled-components";
import SearchForm from "./SearchForm";

const QrScanner = styled.video`
  width: 100%;
  height: calc(100% - 165px);
  object-fit: cover;
  position: absolute;
  top: 165px;
  z-index: -1;
`;

const Container = styled(BackgroundContainer)`
  height: 100vh;
  position: absolute;
  z-index: 1;
  height: 100vh;
  width: 100%;
`;

const QrScanPage = () => {
  const [error, setError] = useState(null);
  const { ref } = useZxing({
    onError: error => {
      setError({
        message: "Error",
        description: error.message,
      });
    },
    paused: !!error,
    timeBetweenDecodingAttempts: 1000,
    constraints: {
      video: {
        facingMode: "environment",
      },
    },
    onDecodeResult(result) {
      if (result) {
        if (/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(result.getText())) {
          navigate(`/citizen/${result.getText()}`);
        } else {
          ``;
          setError({
            message: "Error",
            description: "Invalid QR Code",
          });
        }
      }
    },
  });

  const navigate = useNavigate();

  return (
    <Container>
      <SearchForm />
      <QrScanner ref={ref} />

      {error ? (
        <>
          <Alert message={error.message} description={error.description} type="error" showIcon />
          <Button
            onClick={() => {
              setError(null);
            }}
            type="primary"
            block>
            Retry
          </Button>
        </>
      ) : null}
    </Container>
  );
};

export default QrScanPage;
