import React, { useEffect, useState } from "react";
import SocialServiceContext from "./Context";
import { getHistory } from "services/citizen";

const SocialServiceProvider = ({ children }) => {
  const [citizen, setCitizen] = useState(null);
  const [history, setHistory] = useState(null);

  useEffect(() => {
    if (citizen) {
      getHistory(citizen.id).then(history => setHistory(history));
    }
  }, [citizen]);

  return (
    <SocialServiceContext.Provider
      value={{
        citizen,
        setCitizen,
        history,
      }}>
      {children}
    </SocialServiceContext.Provider>
  );
};

export default SocialServiceProvider;
